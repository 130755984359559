<template>
  <main class="page category-main-page">
    <div class="container-padding">
      <div class="page__inner category-main-page__inner">
        <h1 class="title">{{ category.title }}</h1>
        <div class="categories-section">
          <SetsCategoryTabsComponent :data="categories" />
          <div class="categories-section--right">
            <!--            <SliderComponent-->
            <!--              :items="[1, 2, 3, 4, 5, 6, 7, 8, 9]"-->
            <!--              v-slot="{ item }"-->
            <!--              :slider-options="sliderFirstOptions"-->
            <!--              :title="'Скидки со вкусом'"-->
            <!--              :route="{ name: 'home' }"-->
            <!--              button-title="Ещё 135 товаров"-->
            <!--              class="category-main_slider-first"-->
            <!--            >-->
            <!--              <CardComponent :data="item" :grid="true" />-->
            <!--            </SliderComponent>-->
            <SliderComponent
              v-for="(category, i) in categories"
              :key="i"
              :items="category.products.data"
              v-slot="{ item }"
              :slider-options="sliderOptions"
              :title="category.title"
              :route="{ name: 'category-open', params: { link: category.link } }"
              button-title="Все товары"
              :id="category.link"
              class="category-main__slider"
            >
              <CardComponent :data="item" />
            </SliderComponent>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SetsCategoryTabsComponent from "../sets/components/SetsCategoryTabsComponent.vue";
import SliderComponent from "components/SliderComponent.vue";
import CardComponent from "components/CardComponent.vue";
import CATEGORY_MAIN_PAGE_STORE from "@/graphql/pages/store/CategoryMainPage.graphql";
import { moreButtonTitle } from "@/utils";

export default {
  name: "CategoryMainPage",
  async asyncData({ apollo, store, route }) {
    let token = store.state.auth_token;
    await apollo.clients.store
      .query({
        query: CATEGORY_MAIN_PAGE_STORE,
        variables: {
          link: route.params.link,
        },
        context: {
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      })
      .then(({ data }) => {
        store.state.category_main_page.category = data.category;
      });
  },
  data() {
    return {
      first: 10,
      sliderFirstOptions: {
        slidesPerView: 2.2,
        spaceBetween: 24,
      },
      sliderOptions: {
        slidesPerView: 2,
        spaceBetween: 10,
        breakpoints: {
          420: {
            slidesPerView: 2.2,
            spaceBetween: 15,
          },
          680: {
            slidesPerView: 3.2,
            spaceBetween: 24,
          },
        },
      },
    };
  },
  computed: {
    categories() {
      return this.category.child_categories.filter(
        (item) => item.products && item.products.data && item.products.data.length
      );
    },
    category() {
      return this.$store.state.category_main_page.category;
    },
  },
  methods: {
    moreButtonTitle,
  },
  components: {
    SliderComponent,
    SetsCategoryTabsComponent,
    CardComponent,
  },
  metaInfo() {
    let title = this.$store.state.category_main_page.category.title;
    return {
      title,
      meta: [
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
      ],
    };
  },
};
</script>

<style lang="stylus">
.category-main-page {
  &__inner {
    grid-gap 24px
  }

  .category-main__slider {
    padding-top: var(--header-main-height)
    margin-top: calc(-1 * var(--header-main-height))
  }
  .slider {
    height: auto;
  }
  //.product-card {
  //  height: fit-content;
  //}
}
</style>
